import { Input } from "antd";
import { InputContainer } from "./styled";

const InputCommon = ({
  value,
  defaultValue,
  placeHolder,
  onChange,
  disabled,
  suffix,
  prefix,
  type,
  accept,
  onFocus,
  allowClear,
  maxLength,
  mode,
}: any) => {
  return (
    <InputContainer>
      <Input
        accept={accept}
        value={value}
        prefix={prefix}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        onChange={onChange}
        suffix={suffix}
        type={type}
        onFocus={onFocus}
        allowClear={allowClear}
        maxLength={maxLength}
        inputMode={mode || ""}
      />
    </InputContainer>
  );
};

export default InputCommon;
