export const convertFormatNumber = (number: any) =>
  `${new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(number)
    .replace("$", "")}`;
// Convert to 100000 => 100,000.00

export const toFixedWithoutRounding = (number: any, precision: any) => {
  const factor = Math.pow(10, precision);
  const tempNumber = Math.trunc(number * factor);
  return (tempNumber / factor).toFixed(precision);
};
export const convertFixed = (value: any) => {
  if (Number(value) === 0) // nếu value < 0.00000009
    return (value?.toFixed(0))?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // thì show giá trị 9 sốthập phân
  else if (Number(value) < 9e-5) // nếu value < 0.00000009
    return (value?.toFixed(9)) // thì show giá trị 9 sốthập phân
  else if (Number(value) < 1) // nếu value < 1
    return (value?.toFixed(4)) // thì show giá trị 4 số thập phân
  else if (Number(value) > 1e3) // nếu value lớn hơn 1e5 (100000)
    return (value?.toFixed(0))?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") //thì show giá trị 0 số thập phân
  else return (value.toFixed(2))

};
// export const convertFixed = (value: any) => {
//   if (Number(value) == 0) // nếu value < 0.00000009
//     return (value.toFixed(0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") // thì show giá trị 9 sốthập phân
//   else if (Number(value) < 9e-5) // nếu value < 0.00000009
//     return (value.toFixed(9)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") // thì show giá trị 9 sốthập phân
//   else if (Number(value) < 1) // nếu value < 1
//     return (value.toFixed(4)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") // thì show giá trị 4 số thập phân
//   else if (Number(value) > 1e5) // nếu value lớn hơn 1e5 (100000)
//     return (value.toFixed(0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",") //thì show giá trị 0 số thập phân
//   else return (value.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

// };
